<template>
  <div>
    <div class='header'>
      <div class='header__content'>
        <slot name='logo'></slot>
        <nav class='mobile-nav'>
          <a href='#' @click.prevent.stop='toggleMenu' class='header__a header__button'><i class='fas fa-bars'></i>Menu</a>
        </nav>
        <nav class='header__nav header__nav--main' style='margin-left: 1rem'>
          <a
          :class='{"header__a header__a--companies": true, "active": active == 2 }'
          :href='companies_path'>{{structure_name}}</a>
          <a :class='{" header__a header__a--knowledge-base": true, "active": active == 3}' :href='articles_path'>KB</a>
          <a href='#' class='header__a header__a--dropmenu' @click.prevent.stop='switchSubMenu'><down-arrow></down-arrow></a>
        </nav>
        <a class='header__search' @click.prevent.stop='switchSearch'>
          <icon></icon>
          <p>Search...</p>
        </a>
      
        <a href='#' @click.prevent.stop="switchDropper" class='profile'>
          <img :src='image' width='32' height='32'>
          <article>
            <h2>{{user_name}}</h2>
            <h3>{{user_role}} - {{account_name}}</h3>
          </article>
          <down-arrow></down-arrow>
        </a>
      </div>
    </div>

    <div class='header__dropmenu__menu' v-if='showSubMenu'>
      <a v-if='is_admin' :class='{"header__dropmenu__menu__a header__a--admin": true, "active": active == 7}' :href='admin_path'>
        <i class='fas fa-cog'></i>
        <article>
          <h1>Admin</h1>
          <p>Customize and administrate</p>
        </article>
      </a>
      <a v-if='show_sync_bar' @click.prevent.stop="switchSyncBar" :class="{'header__dropmenu__menu__a': true, 'active': showSyncBar }" href='#'>
        <i class='fas fa-redo'></i>
        <article>
          <h1>Resync</h1>
          <p>Refresh your active integrations</p>
        </article>
      </a>
      <a :class='{"header__dropmenu__menu__a header__a--knowledge-base": true, "active": active == 8}' :href='vault_path'>
        <i class='fas fa-lock'></i>
        <article>
          <h1>My Vault</h1>
          <p>Store your personal passwords</p>
        </article>
      </a>
      
    </div>
    <div class='drop-header' v-if='showDrop'>
      <a
            :class='{"header__a header__a--home": true, "active": active == 1}' :href='dashboards_path'><i class='fas fa-home'></i></a>
      <a
      :class='{"header__a header__a--companies": true, "active": active == 2 }'
      :href='companies_path'><i class='fas fa-briefcase'></i></a>
      <a :class='{" header__a header__a--knowledge-base": true, "active": active == 3}' :href='articles_path'><i class='fas fa-book-reader'></i></a>
      <a v-if='is_admin' :class='{"header__a header__a--admin": true, "active": active == 7}' :href='admin_path'><i class='fas fa-cog'></i></a>
      <a v-if='show_sync_bar' @click.prevent.stop="switchSyncBar" :class="{'header__a': true, 'active': showSyncBar }" href='#'><i class="fas fa-sync" style='margin: 0'></i></a>
      <a :href='vault_path' :class="{'header__a': true, 'active': active == 8 }"><i class="fas fa-key" style='margin: 0'></i></a>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import Icon from "../components/icon.vue";
import DownArrow from "../components/DownArrow.vue";
export default {
  components: {
    Icon,
    DownArrow
  },
  props: [
    "name", 
    "image", 
    "count",
    "tab", 
    "show_menu",
    "user_role",
    "show_sync_bar",
    "account_name",
    "user_name",
    "structure_name",
    "dashboards_path",
    "companies_path",
    "process_templates_path",
    "articles_path",
    "admin_path",
    "vault_path",
    "is_admin",
    "billing_successful"
    ],
  mounted () {
    
  },
  computed: {
    ...mapGetters("globals", {
      'showVault': 'showVault',
      'showSyncBar': 'showSyncBar',
      'showDropper': 'showDropper',
      'showSearcher': 'showSearcher',
      'showSubMenu': 'showSubMenu',
    }),
    active () {
      if (this.showVault || this.showSyncBar || this.showSubMenu) {
        return 0
      } else if (this.tab == "dashboard") {
        return 1
      } else if (this.tab == "companies") {
        return 2
      } else if (this.tab == "articles") {
        return 3
      } else if (this.tab == "process_templates") {
        return 4
      } else if (this.tab == "admin") {
        return 7
      } else if (this.tab == "vault") {
        return 8
      }
    }
  },
  methods: {
    toggleMenu () {

      this.showDrop = !this.showDrop;

      var companySidebar = document.querySelector(".company-sidebar");

      if (companySidebar.style.display === "none" || companySidebar.style.display === "" ) {
        companySidebar.style.display = "block";
      } else {
        companySidebar.style.display = "none";
      }

    },
    toggle(newSub) {
      this.$store.commit('globals/changeSub', newSub)
    },
    switchVault () {
      if (this.showVault) {
        this.toggle(0)
      } else {
        this.toggle(4)
        this.$nextTick(() => document.querySelector("#vaultSearchInput").focus())
      }
    },
    switchSubMenu () {
      if (this.showSubMenu) {
        this.toggle(0)
      } else {
        this.toggle(6)
      }
    },
    switchSearch () {
      if (this.showSearcher) {
        this.toggle(0)
      } else {
        this.toggle(2)
        this.$nextTick(() => document.querySelector("#mainSearchInput").focus())
      }
    },
    switchDropper () {
      if (this.showDropper) {
        this.toggle(0)
      } else {
        this.toggle(3)
      }
    },
    switchSyncBar () {
      if (this.showSyncBar) {
        this.toggle(0)
      } else {
        this.toggle(5)
      }
    },
    toggleDrop () {

    }
  },
  data () {
    return {
      isSubMenuShown: false,
      isShown: false,
      showDrop: false
    }
  }
}
</script>

